import React from "react";
import Layout from "../../layouts/layout";
import { Link } from "gatsby";

import part1 from "../../images/video_miniature/part1.png";
import part2 from "../../images/video_miniature/part2.png";
import part3 from "../../images/video_miniature/part3.png";
import part4 from "../../images/video_miniature/part4.png";
import part5 from "../../images/video_miniature/part5.png";
import pasimage from "../../images/pasimage.png";

import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

import { useUserStore } from "../../store/user";
import { faqs } from "../../mock/faqs";

const Tutoriel = () => {
    const { setVideo, lien } = useUserStore();
    const Video_URL = process.env.VIDEO_URL;
    const lien_default = `${Video_URL}Part1-BDAdresse-InterfaceAccueil.mp4`;

    const video: {
        id: number;
        fileName: string;
        lien: string;
        miniature: string;
        title: string;
        description: string;
    }[] = [
        {
            id: 1,
            fileName: "Part1-BDAdresse-InterfaceAccueil.mp4",
            lien: `${Video_URL}Part1-BDAdresse-InterfaceAccueil.mp4`,
            miniature: part1,
            title: "Part1-BDAdresse-InterfaceAccueil",
            description:
                "Présentation de l’interface d’accueil et des différentes fonctionnalités proposées",
        },
        {
            id: 2,
            fileName: "Part2-BDAdresse-FonctionsCarte.mp4",
            lien: `${Video_URL}Part2-BDAdresse-FonctionsCarte.mp4`,
            miniature: part2,
            title: "Part2-BDAdresse-FonctionsCarte",
            description:
                "Présentation des fonctionnalités de la partie cartographique",
        },
        // {
        //     id: 3,
        //     fileName: "Part3-BDAdresse-ServicesGrandPublic.mp4",
        //     lien: `${Video_URL}Part3-BDAdresse-ServicesGrandPublic.mp4`,
        //     miniature: part3,
        //     title: "Part3-BDAdresse-ServicesGrandPublic",
        //     description: "Présentation des services de demande de nouvelle adresse, de demande de certificat et de signalement d’erreur"
        // },
        {
            id: 3,
            fileName: "Part4-BDAdresse-Apropos.mp4",
            lien: `${Video_URL}Part4-BDAdresse-Apropos.mp4`,
            miniature: part4,
            title: "Part3-BDAdresse-Apropos",
            description:
                "Présentation de la page A propos et des dernières activités réalisées dans le cadre du projet",
        },
        {
            id: 4,
            fileName: "Part5-BDAdresse-EspacePro.mp4",
            lien: `${Video_URL}Part5-BDAdresse-EspacePro.mp4`,
            miniature: part5,
            title: "Part4-BDAdresse-EspacePro",
            description:
                "Présentation de l’espace dédié aux professionnels. Cet espace permet d’accéder à un ensemble de services (outil d’export…)",
        },
    ];
    const handleLink = (id: any) => {
        const selectedItem = video.find((item) => item.id === id);
        setVideo(selectedItem);
    };
    return (
        <Layout>
            <div className="tw-text-center tw-my-3">
                <h4>
                    <b>Tutoriel</b>
                </h4>
            </div>
            <div className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-center tw-w-[90%] tw-m-auto tw-space-x-0 sm:tw-space-x-10">
                <div className="tw-w-full tw-mb-6 sm:tw-mb-0">
                    <video
                        src={lien == undefined ? lien_default : lien?.lien}
                        controls
                        className="tw-h-auto tw-m-auto"
                    ></video>
                    <p className="tw-font-bold">
                        {lien == undefined
                            ? "Part1-BDAdresse-InterfaceAccueil"
                            : lien?.title}
                    </p>
                </div>
                <div className="tw-grid tw-gap-4 tw-w-[70%] tw-mb-6 sm:tw-mb-0">
                    {video.map((item: any, key: number) => {
                        return (
                            <div key={key} className="tw-flex">
                                <div className="tw-w-36 tw-max-w-[30%]">
                                    <img
                                        src={item.miniature}
                                        alt="video"
                                        className="tw-cursor-pointer"
                                        onClick={() => handleLink(item.id)}
                                    />
                                </div>
                                <div className="tw-text-left tw-ml-2 tw-w-[68%]">
                                    <p className="tw-text-sm tw-font-bold">
                                        {item.title}
                                    </p>
                                    <p className="tw-text-sm">
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="tw-bg-white tw-px-4 sm:tw-px-10 tw-pt-7 tw-pb-5">
                <div className="">
                    <p className="tw-text-2xl sm:tw-text-2xl tw-font-bold tw-text-primary">
                        FAQs
                    </p>
                    <div className="tw-w-11/12">
                        {faqs.map((item: any, key: number) => {
                            return (
                                <Disclosure
                                    as="div"
                                    className="tw-my-5"
                                    key={key}
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="tw-flex tw-justify-between">
                                                <Disclosure.Button className="tw-flex tw-w-full tw-justify-between tw-text-left tw-text-sm tw-font-medium tw-border-b-2">
                                                    <span>
                                                        {/* {key + 1}°/{" "} */}
                                                        {item.question}
                                                    </span>
                                                    <ChevronUpIcon
                                                        className={`${
                                                            open
                                                                ? "tw-rotate-180 tw-transform"
                                                                : "tw-rotate-90 tw-transform"
                                                        } tw-h-7 tw-w-7 tw-text-orange-500`}
                                                    />
                                                </Disclosure.Button>
                                            </div>
                                            <Disclosure.Panel className="tw-px-0 tw-pt-4 tw-pb-2 tw-text-sm tw-text-gray-500">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.response,
                                                    }}
                                                />
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Tutoriel;
